<style lang="scss" scoped>
    .cropper{
        width: 500px;
    }
    .cropper {
      width: 100%;
      height: 400px;
    }
    .upload-image-cropper {
      border: solid 1px #EEE;
      height: 100%;
      width: 100%;
    }
    .content {
      display: flex;
      justify-content: space-between;
    }
    .cropper-area {
      width: 100%;
    }
    .img-cropper{
      width: 100%;
      height: 400px;
    }
    .drag-enter {
      border: 2px dashed gray;
    }
</style>
<template>
  <!-- <v-row no-gutters :class="addClass" id="dropBox"  @drop.prevent="dropFile"
           @dragover.prevent @dragenter.prevent
           @dragenter="dragEnter" @dragleave="dragLeave"
           @click="trigger">-->
  <v-row
    id="dropBox"
    no-gutters
    :class="{'drag-enter': drag_enter }"
    v-bind="$attrs"
    @drop.prevent="dropFile"
    @dragover.prevent
    @dragenter.prevent
    @dragenter="dragEnter"
    @dragleave="dragLeave"
    @click.stop="onFocus"
  >
    <v-col
      v-if="showControls"
      class="grow"
      style="cursor: pointer;position: relative"
      @click.stop="onFocus"
    >
      <slot name="input">
        <v-btn
          v-if="!icon"
          v-model="filename"
          :text="text"
          depressed
          :small="small"
          :class="btnClass"
          :color="color"
          :loading="loading"
        >
          <slot name="btn-label">
            <span>Choose file</span>
          </slot>
        </v-btn>
        <v-btn
          v-else
          v-model="filename"
          icon
          :loading="loading"
          :color="iconColor"
        >
          <v-icon
            :color="iconColor"
            v-text="iconName"
          />
        </v-btn>
      </slot>
      <!--        v-if="loading && input"-->
      <!--      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
      ></v-progress-circular>-->
    </v-col>
    <v-col
      v-if="hideLabel"
      class="grow pr-2"
    >
      <v-text-field
        ref="fileTextField"
        v-model="filename"
        :label="label"
        :placeholder="label"
        :required="required"
        :disabled="disabled"
        :class="classes"
        :width="width"
        :append-icon="iconName"
        @click.native="onFocus"
      />
    </v-col>
    <v-col
      v-if="extra"
      class="grow"
    >
      <v-text-field
        v-model="name"
        single-line
        :placeholder="$t('Enter Name')"
        :label="$t('Enter Name')"
        :required="required"
        :disabled="disabled"
      />
    </v-col>
    <v-col
      v-if="path !== '' && showDownload"
      class="shrink"
    >
      <v-btn
        target="_blank"
        icon
        small
        :href="path"
      >
        <v-icon
          small
          color="success"
        >
          mdi-cloud-download
        </v-icon>
      </v-btn>
    </v-col>

    <input
      ref="fileInput"
      type="file"
      style="display: none;"
      :accept="accept"
      :multiple="false"
      :disabled="disabled"
      @change="onFileChange"
    >
    <v-dialog
      v-model="crop_dialog"
      max-width="650"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card
        flat
        tile
      >
        <v-card-title>
          {{ $t("Crop") }}
          <v-spacer />
          <v-btn
            small
            text
            @click="cancelCrop"
          >
            {{ $t("Close") }}
          </v-btn>
        </v-card-title>
        <v-divider />

        <v-row
          align="center"
          :style="{'max-height':$vuetify.breakpoint.mdAndUp? '500px':'700px'}"
          style="max-width: 100%"
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
            style="height: 100%"
          >
            <v-card
              flat
              class="mx-auto"
              style="max-height: 100%"
            >
              <cropper
                ref="cropper"
                :src="image"
                :restrictions="pixelsRestriction"
                classname="cropper"
                class="cropper"
                :stencil-props="{
                  aspectRatio: aspectRatio,
                }"
                @change="change"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-card-actions class="mt-4">
          <v-btn
            small
            text
            outlined
            depressed
            @click="cancelCrop"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            depressed
            color="primary"
            @click="saveCrop"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {
    Cropper,
  } from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css'

  export default {
    name: 'FileUpload',
    components: { Cropper },
    props: {
      value: {
        type: [Array, String],
      },
      addClass: {
        type: [Array, String],
      },
      btnClass: {
        type: [Array, String],
      },
      accept: {
        type: String,
        default: '*',
      },
      crop: {
        type: Boolean,
        default: false,
      },
      aspectRatio: {
        type: Number,
        default: 1,
      },
      minCropWidth: {
        type: Number,
        default: 10,
      },
      minCropHeight: {
        type: Number,
        default: 10,
      },
      maxCropWidth: {
        type: Number,
        default: 400,
      },
      maxCropHeight: {
        type: Number,
        default: 300,
      },
      btnLabel: {
        type: String,
        default: 'Choose file',
      },
      iconName: {
        type: String,
        default: 'mdi-image-plus',
      },
      iconColor: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'primary',
      },
      label: {
        type: String,
        default: 'Please choose...',
      },
      folder: String,

      inputname: {
        type: String,
        default: 'file',
      },
      name: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: 'fileupload',
      },

      classes: {
        type: String,
        default: '',
      },
      width: {
        type: String,
        default: '',
      },
      showControls: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      showDownload: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: Boolean,
        default: false,
      },
      btn: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      filter: {
        type: Boolean,
        default: true,
      },
      extra: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean, // not yet possible because of data
        default: false,
      },

      event: {
        type: Function,
        default (event, data) {
        },
      },
    },
    data () {
      return {
        inputvalue: '',
        filename: '',
        loading: false,
        crop_dialog: false,
        drag_enter: false,
        image: null,
        coordinates: {
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        },
      }
    },
    computed: {
      path () {
        return this.value
      },
    },
    watch: {
      value (val) {
        this.filename = val
      },
    },
    mounted () {
      this.filename = this.value
      console.log('mounted')
    },
    created () {
      this.filename = this.value
    },

    methods: {
      dragEnter (ev) {
        ev.target.style.border = '3px dash red'
        this.drag_enter = true
      },
      dragLeave (ev) {
        ev.target.style.border = '0px dash grey'
        this.drag_enter = false
      },
      dropFile (e) {
        this.drag_enter = false
        this.onFileChange(e)
      },
      change ({ coordinates, canvas }) {
        this.coordinates = coordinates
      },
      resize (width, height, left, top) {
        this.$refs.cropper.setCoordinates({
          width,
          height,
          left,
          top,
        })
      },
      pixelsRestriction (minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight) {
        return {
          minWidth,
          minHeight,
          maxWidth,
          maxHeight,
        }
      },
      cancelCrop () {
        this.crop_dialog = false
        this.image = null
      },
      saveCrop () {
        this.crop_dialog = false

        const { coordinates, canvas } = this.$refs.cropper.getResult()
        this.coordinates = coordinates

        // You able to do different manipulations at a canvas
        // but there we just get a cropped image
        this.image = canvas.toDataURL()
        const _this = this
        canvas.toBlob(blob => {
          const formData = new FormData()
          formData.append(_this.inputname, blob, _this.filename)
          formData.append('filter', _this.filter)
          formData.append('coordinates', _this.coordinates)
          _this.submit(formData)
        }, 'image/jpeg')
      },
      getFormData (files) {
        const data = new FormData()
        for (const file of files) {
          data.append(this.inputname, file, file.name)
        }

        return data
      },
      onFocus () {
        if (!this.disabled) {
          this.$refs.fileInput.value = null
          this.$refs.fileInput.click()
        }
      },
      onFileChange ($event) {
        const files = $event.target.files || $event.dataTransfer.files
        const form = this.getFormData(files)

        if (this.crop) {
          if (files && files[0]) {
            // create a new FileReader to read this image and convert to base64 format
            const reader = new FileReader()

            // Define a callback function to run, when FileReader finishes its job
            const _this = this
            reader.onload = e => {
              // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
              // Read image as base64 and set to imageData
              this.image = e.target.result
              this.crop_dialog = true
            }

            // Start the reader job - read file as a data url (base64 format)
            this.filename = files[0].name
            reader.readAsDataURL(files[0])
          }
        } else {
          if (files) {
            if (files.length > 0) {
              this.filename = [...files].map(file => file.name).join(', ')
            } else {
              this.filename = null
            }
          } else {
            this.filename = $event.target.value.split('\\').pop()
          }
          if (this.folder) {
            form.append('folder', this.folder)
          }
          form.append('filter', this.filter)

          this.$emit('formData', form)
          this.submit(form)
        }
      },
      submit (form) {
        // const url = "admin/customer/photo/" + this.customer.id;
        // upload data to the server
        //    this.currentStatus = STATUS_SAVING;
        // this.$emit('input', null);
        if (this.url.length > 2) {
          this.loading = true

          // this.disabled = true;
          const _this = this
          form.name = this.name

          // eslint-disable-next-line no-undef
          axios.post(this.url, form)
            .then(response => {
              // this.disabled = false;
              this.loading = false
              const d = response.data
              if (d.error) {
                (this.event)('error', d.message)
              }
              if (d.path) {
                this.inputvalue = d.url

                _this.$emit('input', d.url)
                this.updateModel(d.url)
                this.filename = d.url
              } else {

              }
              if (this.folder) {
                response.data.folder = this.folder
              }
              (this.event)('upload', response.data)
            })
            .catch(error => {
              this.loading = false;

              // this.disabled = false;
              (this.event)('upload_error', error)
              console.log(error)
            })
        }
      },
      updateModel (value) {
        this.inputvalue = value
      },
      updateParent ($event) {
      // alert("yo")
      },
    },
  }
</script>
